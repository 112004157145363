import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  openQuizArrival,
  openQuizDeparture,
  selectQuiz,
  setQuizStep,
} from '../quiz.slice';
import styles from './quizControl.module.scss';

export const QuizControl = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const quiz = useAppSelector(selectQuiz);

  const { step, arrival, departure } = quiz;

  const handleNext = () => {
    if (!arrival) {
      dispatch(openQuizArrival());
      return;
    }

    if (!departure) {
      dispatch(openQuizDeparture());
      return;
    }

    dispatch(setQuizStep(step + 1));
  };

  return (
    <div className={styles.module}>
      {step > 1 && step < 3 && (
        <button
          className={styles.prev}
          type="button"
          onClick={() => dispatch(setQuizStep(step - 1))}
        />
      )}

      {step < 3 && (
        <button className={styles.next} type="button" onClick={handleNext}>
          Далее
        </button>
      )}

      {step === 3 && <button className={styles.confirm}>Отправить</button>}
    </div>
  );
};
