import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { store } from './app/store';
import { Provider } from 'react-redux';
import App from './App';
import { Question } from 'features/question/Question';
import { Quiz } from 'features/quiz/Quiz';
// import * as serviceWorker from './serviceWorker';

import header from './scripts/header';
// import screen from './scripts/screen';
import about from './scripts/about';
import gallery from './scripts/gallery';
import rooms from './scripts/rooms';
import services from './scripts/services';
// import environment from './scripts/environment';
import reviews from './scripts/reviews';

import './styles/index.scss';

header.init();
// screen.init();
about.init();
gallery.init();
rooms.init();
services.init();
// environment.init();
reviews.init();

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <Question />
    </Provider>
  </StrictMode>,
  document.getElementById('question')
);

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <Quiz />
    </Provider>
  </StrictMode>,
  document.getElementById('quiz')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
