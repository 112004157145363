export const ageOptions = [
  { value: '0', name: '0 лет' },
  { value: '1', name: '1 год' },
  { value: '2', name: '2 года' },
  { value: '3', name: '3 года' },
  { value: '4', name: '4 года' },
  { value: '5', name: '5 лет' },
  { value: '6', name: '6 лет' },
  { value: '7', name: '7 лет' },
  { value: '8', name: '8 лет' },
  { value: '9', name: '9 лет' },
  { value: '10', name: '10 лет' },
  { value: '11', name: '11 лет' },
  { value: '12', name: '12 лет' },
  { value: '13', name: '13 лет' },
  { value: '14', name: '14 лет' },
  { value: '15', name: '15 лет' },
  { value: '16', name: '16 лет' },
  { value: '17', name: '17 лет' },
];
