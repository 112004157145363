import { ThanksBodyProps } from './thanksBody.props';
import styles from './thanksBody.module.scss';
import { CloseButton } from 'components/elements/closeButton/CloseButton';

export const ThanksBody = ({ type, onClose }: ThanksBodyProps): JSX.Element => {
  return (
    <div className={styles.module}>
      <CloseButton className={styles.close} onClick={onClose} />
      <img className={styles.bg1} src="/build/images/bg-1.svg" alt="bg" />
      <img className={styles.bg2} src="/build/images/bg-2.svg" alt="bg" />

      <span className={styles.icon} />

      <h3 className={styles.title}>
        {type === 'request'
          ? 'Спасибо, заявка отправлена!'
          : 'Спасибо, Ваш отзыв отправлен!'}
      </h3>
      <p className={styles.subtitle}>
        {type === 'request'
          ? 'Мы свяжемся с вами в ближайшее время!'
          : 'В ближайшее время он будет добавлен на сайт!'}
      </p>
    </div>
  );
};
