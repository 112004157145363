import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Modal } from 'components/layout/modal/Modal';
import { Portal } from 'components/other/portal/Portal';
import { Transition } from 'components/other/transition/Transition';
import { closeTextModal, selectCommon } from '../common.slice';
import { TextModalBody } from './textModalBody/TextModalBody';

export const TextModal = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const common = useAppSelector(selectCommon);

  return (
    <Transition active={common.ui.textModalOpened} timeout={300}>
      {(transitionStyles) => (
        <Portal blocked>
          <Modal
            onOverlayClick={() => dispatch(closeTextModal())}
            className={transitionStyles}
          >
            <TextModalBody
              type={common.textModalType}
              onClose={() => dispatch(closeTextModal())}
            />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
