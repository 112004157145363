import SmoothScroll from 'smooth-scroll';

const headerEl = document.querySelector('.header');
const headerButton = document.querySelector('.header__button');

const initMobileMenu = () => {
  headerButton?.addEventListener('click', () => {
    headerEl?.classList.toggle('header_opened');
  });

  document.addEventListener('click', (event) => {
    if (
      headerEl?.classList.contains('header_opened') &&
      event.target instanceof HTMLElement &&
      !headerEl?.contains(event.target)
    ) {
      headerEl.classList.remove('header_opened');
    }
  });
};

const initNavButtons = () => {
  const buttons =
    document.querySelectorAll<HTMLAnchorElement>('.header__nav a');
  buttons.forEach((item) =>
    item.addEventListener('click', () => {
      headerEl?.classList.remove('header_opened');
    })
  );
};

const initSmoothScroll = () => {
  new SmoothScroll('a[href*="#"]', {
    speed: 200,
  });
};

const initStickyHeader = () => {
  const header = document.querySelector<HTMLHeadingElement>('.header');

  if (!header) {
    return;
  }

  const checkHeader = () => {
    if (header.classList.contains('header_opened')) {
      header.classList.remove('header_opened');
    }

    if (window.pageYOffset > 30) {
      header.classList.add('header_sticked');
    } else {
      header.classList.remove('header_sticked');
    }
  };

  checkHeader();

  window.addEventListener('scroll', () => {
    checkHeader();
  });
};

const header = {
  init: () => {
    initMobileMenu();
    initNavButtons();
    initSmoothScroll();
    initStickyHeader();
  },
};

export default header;
