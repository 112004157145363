import { useAppDispatch, useAppSelector } from 'app/hooks';
import { closeService, selectService } from './service.slice';
import { Modal } from 'components/layout/modal/Modal';
import { Portal } from 'components/other/portal/Portal';
import { Transition } from 'components/other/transition/Transition';
import { ServiceBody } from './serviceBody/ServiceBody';

export const Service = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const service = useAppSelector(selectService);

  return (
    <Transition active={service.ui.serviceOpened} timeout={300}>
      {(transitionStyles) => (
        <Portal blocked>
          <Modal
            className={transitionStyles}
            onOverlayClick={() => dispatch(closeService())}
          >
            <ServiceBody />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
