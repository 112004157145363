import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { ServiceData, ServiceState } from './service.types';

const initialState: ServiceState = {
  name: '',
  text: '',
  images: [],
  ui: {
    serviceOpened: false,
  },
};

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    openService: (state, { payload }: PayloadAction<ServiceData>) => {
      state.name = payload.name;
      state.text = payload.text;
      state.images = payload.images;
      state.ui.serviceOpened = true;
    },
    closeService: (state) => {
      state.ui.serviceOpened = false;
    },
  },
});

export const { openService, closeService } = serviceSlice.actions;

export const selectService = (state: RootState) => state.service;

export const serviceReducer = serviceSlice.reducer;
