import { useAppDispatch } from 'app/hooks';
import { openTextModal } from 'features/common/common.slice';
import { useCookie } from 'hooks/useCookie';
import { useState } from 'react';
import { CloseButton } from '../closeButton/CloseButton';
import styles from './cookie.module.scss';

export const Cookie = (): JSX.Element | null => {
  const dispatch = useAppDispatch();

  const [isShow, setIsShow] = useState(true);
  const [cookie, updateCookie] = useCookie('cookie_notification');

  if (!isShow || cookie) {
    return null;
  }

  return (
    <div className={styles.module}>
      <CloseButton className={styles.close} onClick={() => setIsShow(false)} />
      <p className={styles.text}>
        <span>
          Наш сайт использует файлы Cookie. Продолжая пользоваться сайтом, вы
          соглашаетесь на использование нами ваших файлов Cookie
        </span>{' '}
        <button
          className={styles.detail}
          onClick={() => dispatch(openTextModal('cookie'))}
        >
          Подронее
        </button>
      </p>
      <button
        className={styles.confirm}
        onClick={() => updateCookie('confirmed', 365)}
      >
        Хорошо, спасибо
      </button>
    </div>
  );
};
