import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Modal } from 'components/layout/modal/Modal';
import { Portal } from 'components/other/portal/Portal';
import { Transition } from 'components/other/transition/Transition';
import { closeAddReview, selectAddReview } from './addReview.slice';
import { AddReviewBody } from './addReviewBody/AddReviewBody';

export const AddReview = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const addReview = useAppSelector(selectAddReview);

  return (
    <Transition active={addReview.ui.addReviewOpened} timeout={300}>
      {(transitionStyles) => (
        <Portal blocked>
          <Modal
            className={transitionStyles}
            onOverlayClick={() => dispatch(closeAddReview())}
          >
            <AddReviewBody />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
