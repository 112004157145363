import cn from 'classnames';
import { SelectProps } from './select.props';
import styles from './select.module.scss';

export const Select = ({
  options,
  title,
  paddingLeft,
  className,
  ...rest
}: SelectProps): JSX.Element => {
  const moduleStyles = cn(styles.module, className, {
    [styles.titled]: title,
  });

  return (
    <div className={moduleStyles}>
      {title && <span className={styles.title}>{title}</span>}
      <select
        className={styles.select}
        {...(paddingLeft ? { style: { paddingLeft: `${paddingLeft}px` } } : {})}
        {...rest}
      >
        {options.map((item) => (
          <option key={item.value} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};
