const initButton = () => {
  const about = document.querySelector('.about');
  const button = document.querySelector('.about__button');
  button?.addEventListener('click', () => about?.classList.add('about_opened'));
};

const about = {
  init: () => {
    initButton();
  },
};

export default about;
