import { useAppDispatch } from 'app/hooks';
import { Cookie } from 'components/elements/cookie/Cookie';
import { AddReview } from 'features/addReview/AddReview';
import { openAddReview } from 'features/addReview/addReview.slice';
import { Calculation } from 'features/calculation/Calculation';
import { openCalculation } from 'features/calculation/calculation.slice';
import { Callback } from 'features/callback/Callback';
import { openCallback } from 'features/callback/callback.slice';
import { openTextModal } from 'features/common/common.slice';
import { TextModal } from 'features/common/textModal/TextModal';
import { Thanks } from 'features/common/thanks/Thanks';
import { Review } from 'features/review/Review';
import { openReview } from 'features/review/review.slice';
import { Service } from 'features/service/Service';
import { openService } from 'features/service/service.slice';
import { useEffect } from 'react';
import { openFromReact } from 'utils/helpers/browser.helpers';

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    openFromReact('.header__callback', () => dispatch(openCallback()));
    openFromReact('.footer__callback', () => dispatch(openCallback()));
    openFromReact('.screen__booking', () =>
      dispatch(openCalculation({ room: '', type: 'calculation' }))
    );
    // openFromReact('.screen__calculation', () =>
    //   dispatch(openCalculation({ room: '', type: 'booking' }))
    // );
    openFromReact('.rooms__booking', ({ currentTarget }) => {
      if (currentTarget instanceof HTMLElement) {
        dispatch(
          openCalculation({
            room: currentTarget.dataset.room ?? '',
            type: 'calculation',
          })
        );
      }
    });
    // openFromReact('.rooms__calculation', ({ currentTarget }) => {
    //   if (currentTarget instanceof HTMLElement) {
    //     dispatch(
    //       openCalculation({
    //         room: currentTarget.dataset.room ?? '',
    //         type: 'booking',
    //       })
    //     );
    //   }
    // });
    openFromReact('.services__item', ({ currentTarget }) => {
      if (currentTarget instanceof HTMLElement) {
        dispatch(
          openService({
            name: currentTarget.dataset.name ?? '',
            text: currentTarget.dataset.text ?? '',
            images: currentTarget.dataset.images?.split(',') ?? [],
          })
        );
      }
    });
    openFromReact('.reviews__button', ({ currentTarget }) => {
      if (currentTarget instanceof HTMLElement) {
        dispatch(
          openReview({
            grade: currentTarget.dataset.grade ?? '',
            name: currentTarget.dataset.name ?? '',
            date: currentTarget.dataset.date ?? '',
            text: currentTarget.dataset.text ?? '',
          })
        );
      }
    });
    openFromReact('.reviews__add', () => dispatch(openAddReview()));
    openFromReact('#footer-policy', () => dispatch(openTextModal('policy')));
    openFromReact('#footer-rules', () => dispatch(openTextModal('rules')));
    openFromReact('#footer-cookie', () => dispatch(openTextModal('cookie')));
  }, [dispatch]);

  return (
    <>
      <Callback />
      <Calculation />
      <Service />
      <Review />
      <AddReview />

      <TextModal />
      <Thanks />
      <Cookie />
    </>
  );
}

export default App;
