import cn from 'classnames';
import { Checkbox } from 'components/forms/checkbox/Checkbox';
import styles from './policy.module.scss';
import { PolicyProps } from './policy.props';

export const Policy = ({
  checked,
  onChange,
  onClick,
  required,
  inverted,
  className,
  ...rest
}: PolicyProps): JSX.Element => {
  const moduleStyles = cn(styles.module, className, {
    [styles.inverted]: inverted,
  });

  return (
    <div className={moduleStyles} {...rest}>
      <Checkbox
        className={styles.checkbox}
        checked={checked}
        onChange={onChange}
        required={required}
        inverted={inverted}
      />
      <p className={styles.block}>
        <span className={styles.text}>Даю согласие на обработку</span>{' '}
        <u className={styles.button} onClick={onClick}>
          персональных данных
        </u>
      </p>
    </div>
  );
};
