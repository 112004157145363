const initMore = () => {
  const rooms = document.querySelector('.services');
  const button = document.querySelector('.services__more');
  button?.addEventListener('click', () =>
    rooms?.classList.add('services_opened')
  );
};

const services = {
  init: () => {
    initMore();
  },
};

export default services;
