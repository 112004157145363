import { RefObject, useEffect } from 'react';

export const useOutsideClick = (
  elements: RefObject<HTMLElement>[],
  onClick: () => void
) => {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const { target } = event;

      if (!target || !(target instanceof Node)) {
        return;
      }

      const isClickedOnEl = elements.some((el) => el.current?.contains(target));

      if (!isClickedOnEl) {
        onClick();
      }
    };

    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, [elements, onClick]);
};
