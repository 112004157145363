import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Babies } from 'components/elements/babies/Babies';
import { Select } from 'components/forms/select/Select';
import { getAdultOptions, getBabyOptions } from 'utils/helpers/booking.helpers';
import {
  changeQuizAdults,
  changeQuizBabies,
  closeQuizBabies,
  openQuizBabies,
  selectQuiz,
  setQuizBaby,
} from '../quiz.slice';
import styles from './quizTwo.module.scss';

export const QuizTwo = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const quiz = useAppSelector(selectQuiz);

  return (
    <div className={styles.module}>
      <Select
        className={styles.adults}
        title="Кол-во взрослых"
        paddingLeft={150}
        options={getAdultOptions()}
        onChange={(event) => dispatch(changeQuizAdults(+event.target.value))}
        value={quiz.adults}
      />
      <Select
        className={styles.babies}
        title="Кол-во детей"
        paddingLeft={120}
        options={getBabyOptions()}
        onChange={(event) => dispatch(changeQuizBabies(+event.target.value))}
        value={quiz.babies.length}
      />
      {quiz.babies.length > 0 && (
        <Babies
          className={styles.babyList}
          isOpen={quiz.ui.babiesOpened}
          onClick={() =>
            dispatch(
              quiz.ui.babiesOpened ? closeQuizBabies() : openQuizBabies()
            )
          }
          onOutsideOrConfirmClick={() => dispatch(closeQuizBabies())}
          onSetBaby={(idx, age) => dispatch(setQuizBaby({ idx, age }))}
          babies={quiz.babies}
        />
      )}
    </div>
  );
};
