import { useAppDispatch, useAppSelector } from 'app/hooks';
import { DatePicker } from 'components/forms/DatePicker/DatePicker';
import { batch } from 'react-redux';
import {
  closeQuizArrival,
  closeQuizDeparture,
  openQuizArrival,
  openQuizDeparture,
  selectQuiz,
  setQuizArrival,
  setQuizDeparture,
} from '../quiz.slice';
import styles from './quizOne.module.scss';

export const QuizOne = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const quiz = useAppSelector(selectQuiz);

  return (
    <div className={styles.module}>
      <DatePicker
        className={styles.arrival}
        name="Дата заезда"
        isOpen={quiz.ui.arrivalOpened}
        disabledDaysBefore={new Date()}
        onClick={() =>
          dispatch(
            quiz.ui.arrivalOpened ? closeQuizArrival() : openQuizArrival()
          )
        }
        onOutsideClick={() => dispatch(closeQuizArrival())}
        onDayClick={(date) => {
          batch(() => {
            dispatch(setQuizArrival(date.valueOf()));
            dispatch(closeQuizArrival());
          });
        }}
        value={quiz.arrival}
      />
      <DatePicker
        className={styles.departure}
        name="Дата отъезда"
        isOpen={quiz.ui.departureOpened}
        disabledDaysBefore={quiz.arrival ? new Date(quiz.arrival) : new Date()}
        onClick={() =>
          dispatch(
            quiz.ui.departureOpened ? closeQuizDeparture() : openQuizDeparture()
          )
        }
        onOutsideClick={() => dispatch(closeQuizDeparture())}
        onDayClick={(date) => {
          batch(() => {
            dispatch(setQuizDeparture(date.valueOf()));
            dispatch(closeQuizDeparture());
          });
        }}
        value={quiz.departure}
      />
    </div>
  );
};
