import cn from 'classnames';
import styles from './textarea.module.scss';
import { TextareaProps } from './textarea.props';

export const Textarea = ({
  className,
  ...rest
}: TextareaProps): JSX.Element => {
  const moduleStyles = cn(styles.module, className);

  return <textarea className={moduleStyles} {...rest} />;
};
