import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Policy } from 'components/elements/policy/Policy';
import { Input } from 'components/forms/input/Input';
import { openTextModal } from 'features/common/common.slice';
import {
  selectQuiz,
  setQuizName,
  setQuizPhone,
  toggleQuizPolicy,
} from '../quiz.slice';
import styles from './quizThree.module.scss';

export const QuizThree = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const quiz = useAppSelector(selectQuiz);

  return (
    <div className={styles.module}>
      <Input
        className={styles.name}
        placeholder="Имя"
        value={quiz.name}
        onChange={(event) => dispatch(setQuizName(event.target.value))}
      />
      <Input
        className={styles.phone}
        placeholder="Телефон"
        type="tel"
        pattern="^\+?[0-9()\- ]+"
        required
        value={quiz.phone}
        onChange={(event) => dispatch(setQuizPhone(event.target.value))}
      />
      <Policy
        className={styles.policy}
        checked={quiz.policyChecked}
        onChange={() => dispatch(toggleQuizPolicy())}
        onClick={() => dispatch(openTextModal('policy'))}
      />
    </div>
  );
};
