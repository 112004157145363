import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CloseButton } from 'components/elements/closeButton/CloseButton';
import { openTextModal, openThanks } from 'features/common/common.slice';
import { FormEventHandler } from 'react';
import {
  clearAddReview,
  closeAddReview,
  selectAddReview,
  setAddReviewEmail,
  setAddReviewGrade,
  setAddReviewName,
  setAddReviewText,
  toggleAddReviewPolicy,
} from '../addReview.slice';
import styles from './addReviewBody.module.scss';
import { Input } from 'components/forms/input/Input';
import { Textarea } from 'components/forms/textarea/Textarea';
import { Policy } from 'components/elements/policy/Policy';
import { Range } from 'components/forms/range/Range';

export const AddReviewBody = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const addReview = useAppSelector(selectAddReview);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    dispatch(closeAddReview());
    setTimeout(() => dispatch(openThanks('addReview')), 300);

    await fetch('/ajax/addReview.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        grade: addReview.grade,
        name: addReview.name,
        email: addReview.email,
        text: addReview.text,
      }),
    });

    dispatch(clearAddReview());
  };

  return (
    <div className={styles.module}>
      <CloseButton
        className={styles.close}
        onClick={() => dispatch(closeAddReview())}
      />
      <img className={styles.bg1} src="/build/images/bg-1.svg" alt="bg" />
      <img className={styles.bg2} src="/build/images/bg-2.svg" alt="bg" />

      <form className={styles.form} onSubmit={handleSubmit}>
        <h2 className={styles.title}>Оставить отзыв</h2>

        <div className={styles.grade}>
          <p className={styles.gradeTitle}>Ваша оценка</p>
          <Range
            className={styles.range}
            min={1}
            max={5}
            step={1}
            value={addReview.grade}
            onInput={(event) =>
              dispatch(setAddReviewGrade(+event.currentTarget.value))
            }
            hasScale
          />
        </div>

        <Input
          className={styles.name}
          placeholder="Имя"
          required
          value={addReview.name}
          onChange={(event) => dispatch(setAddReviewName(event.target.value))}
        />
        <Input
          className={styles.email}
          placeholder="E-mail"
          required
          type="email"
          value={addReview.email}
          onChange={(event) => dispatch(setAddReviewEmail(event.target.value))}
        />
        <Textarea
          className={styles.text}
          placeholder="Ваш отзыв"
          required
          value={addReview.text}
          onChange={(event) => dispatch(setAddReviewText(event.target.value))}
        />

        <Policy
          className={styles.policy}
          checked={addReview.policyChecked}
          onChange={() => dispatch(toggleAddReviewPolicy())}
          onClick={() => dispatch(openTextModal('policy'))}
        />
        <button className={styles.button}>Отправить</button>
      </form>
    </div>
  );
};
