export const getElementOffset = (el: HTMLElement) => {
  let top = 0;
  let left = 0;

  let element = el;

  do {
    top += element.offsetTop;
    left += element.offsetLeft;
    const parent = element.offsetParent;

    if (parent instanceof HTMLElement) {
      element = parent;
    } else {
      break;
    }
  } while (element);

  return {
    top,
    left,
  };
};

export const openFromReact = (
  selector: string,
  callback: (event: MouseEvent) => void
): void => {
  document
    .querySelectorAll<HTMLElement>(selector)
    .forEach((el) => el.addEventListener('click', callback));
};
