import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { CommonState, TextModalType, ThanksModalType } from './common.types';

const initialState: CommonState = {
  textModalType: 'policy',
  thanksModalType: 'request',
  ui: {
    textModalOpened: false,
    thanksOpened: false,
  },
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    openTextModal: (state, { payload }: PayloadAction<TextModalType>) => {
      state.textModalType = payload;
      state.ui.textModalOpened = true;
    },
    closeTextModal: (state) => {
      state.ui.textModalOpened = false;
    },

    openThanks: (state, { payload }: PayloadAction<ThanksModalType>) => {
      state.thanksModalType = payload;
      state.ui.thanksOpened = true;
    },
    closeThanks: (state) => {
      state.ui.thanksOpened = false;
    },
  },
});

export const {
  openTextModal,
  closeTextModal,

  openThanks,
  closeThanks,
} = commonSlice.actions;

export const selectCommon = (state: RootState) => state.common;

export const commonReducer = commonSlice.reducer;
