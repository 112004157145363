import cn from 'classnames';
import styles from './modal.module.scss';
import { ModalProps } from './modal.props';

export const Modal = ({
  onOverlayClick,
  children,
  className,
}: ModalProps): JSX.Element => {
  const moduleStyles = cn(styles.module, className);

  return (
    <section
      className={moduleStyles}
      onClick={(event) =>
        event.target === event.currentTarget && onOverlayClick()
      }
    >
      <div className={styles.content}>{children}</div>
    </section>
  );
};
