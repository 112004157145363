import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Modal } from 'components/layout/modal/Modal';
import { Portal } from 'components/other/portal/Portal';
import { Transition } from 'components/other/transition/Transition';
import { closeCalculation, selectCalculation } from './calculation.slice';
import { CalculationBody } from './calculationBody/CalculationBody';

export const Calculation = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const calculation = useAppSelector(selectCalculation);

  return (
    <Transition active={calculation.ui.calculationOpened} timeout={300}>
      {(transitionStyles) => (
        <Portal blocked>
          <Modal
            className={transitionStyles}
            onOverlayClick={() => dispatch(closeCalculation())}
          >
            <CalculationBody />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
