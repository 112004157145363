import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { AddReviewState } from './addReview.types';

const initialState: AddReviewState = {
  grade: 5,
  name: '',
  email: '',
  text: '',
  policyChecked: false,
  ui: {
    addReviewOpened: false,
  },
};

export const addReviewSlice = createSlice({
  name: 'addReview',
  initialState,
  reducers: {
    setAddReviewGrade: (state, action: PayloadAction<number>) => {
      state.grade = action.payload;
    },
    setAddReviewName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setAddReviewEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setAddReviewText: (state, action: PayloadAction<string>) => {
      state.text = action.payload;
    },
    toggleAddReviewPolicy: (state) => {
      state.policyChecked = !state.policyChecked;
    },

    openAddReview: (state) => {
      state.ui.addReviewOpened = true;
    },
    closeAddReview: (state) => {
      state.ui.addReviewOpened = false;
    },
    clearAddReview: (state) => {
      state.grade = 5;
      state.name = '';
      state.email = '';
      state.text = '';
      state.policyChecked = false;
    },
  },
});

export const {
  setAddReviewGrade,
  setAddReviewName,
  setAddReviewEmail,
  setAddReviewText,
  toggleAddReviewPolicy,

  openAddReview,
  closeAddReview,
  clearAddReview,
} = addReviewSlice.actions;

export const selectAddReview = (state: RootState) => state.addReview;

export const addReviewReducer = addReviewSlice.reducer;
