import cn from 'classnames';
import { RangeProps } from './range.props';
import styles from './range.module.scss';

export const Range = ({
  className,
  min,
  max,
  value,
  hasScale,
  ...rest
}: RangeProps): JSX.Element => {
  const moduleStyles = cn(styles.module, className);

  let bgSize = 0;
  if (value && min && max) {
    bgSize = ((+value - +min) * 100) / (+max - +min);
  }

  return (
    <div className={moduleStyles}>
      <div className={styles.range}>
        <input
          className={styles.input}
          style={{ backgroundSize: `${bgSize}% 100%` }}
          type="range"
          value={value}
          min={min}
          max={max}
          {...rest}
        />
      </div>
      {hasScale && min && max && (
        <div className={styles.scale}>
          {Array(+max - +min + 1)
            .fill(null)
            .map((_, idx) => (
              <span>{+min + idx}</span>
            ))}
        </div>
      )}
    </div>
  );
};
