import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { PortalProps } from './portal.props';
import './portal.scss';

const portalEl = document.getElementById('portal');

export const Portal = ({
  children,
  blocked,
}: PortalProps): JSX.Element | null => {
  useEffect(() => {
    if (!blocked || !portalEl?.dataset.amount) {
      return;
    }

    document.body.classList.add('body_blocked');
    portalEl.dataset.amount = `${+portalEl.dataset.amount + 1}`;
    portalEl.scrollTop = 0;

    return () => {
      if (!portalEl.dataset.amount) {
        return;
      }
      portalEl.dataset.amount = `${+portalEl.dataset.amount - 1}`;
      if (portalEl.dataset.amount === '0') {
        document.body.classList.remove('body_blocked');
      }
    };
  }, [blocked]);

  if (!portalEl) {
    return null;
  }

  return createPortal(children, portalEl);
};
